.pe-aspect-h-1 {
  --tw-aspect-h: 1;
}

.pe-aspect-w-1 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 1;
}

.pe-aspect-w-1 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#productessentials-page :is(.pe-sr-only) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#productessentials-page :is(.pe-fixed) {
  position: fixed;
}

#productessentials-page :is(.pe-absolute) {
  position: absolute;
}

#productessentials-page :is(.pe-relative) {
  position: relative;
}

#productessentials-page :is(.pe-inset-0) {
  inset: 0px;
}

#productessentials-page :is(.pe-inset-x-0) {
  left: 0px;
  right: 0px;
}

#productessentials-page :is(.pe-bottom-0) {
  bottom: 0px;
}

#productessentials-page :is(.pe-isolate) {
  isolation: isolate;
}

#productessentials-page :is(.pe-z-10) {
  z-index: 10;
}

#productessentials-page :is(.pe-mx-auto) {
  margin-left: auto;
  margin-right: auto;
}

#productessentials-page :is(.-pe-mt-96) {
  margin-top: -24rem;
}

#productessentials-page :is(.pe-mb-2) {
  margin-bottom: 0.5rem;
}

#productessentials-page :is(.pe-mt-1) {
  margin-top: 0.25rem;
}

#productessentials-page :is(.pe-mt-12) {
  margin-top: 3rem;
}

#productessentials-page :is(.pe-mt-2) {
  margin-top: 0.5rem;
}

#productessentials-page :is(.pe-mt-3) {
  margin-top: 0.75rem;
}

#productessentials-page :is(.pe-mt-4) {
  margin-top: 1rem;
}

#productessentials-page :is(.pe-mt-5) {
  margin-top: 1.25rem;
}

#productessentials-page :is(.pe-mt-6) {
  margin-top: 1.5rem;
}

#productessentials-page :is(.pe-mt-8) {
  margin-top: 2rem;
}

#productessentials-page :is(.pe-block) {
  display: block;
}

#productessentials-page :is(.pe-inline-block) {
  display: inline-block;
}

#productessentials-page :is(.pe-flex) {
  display: flex;
}

#productessentials-page :is(.pe-inline-flex) {
  display: inline-flex;
}

#productessentials-page :is(.pe-grid) {
  display: grid;
}

#productessentials-page :is(.pe-hidden) {
  display: none;
}

#productessentials-page :is(.pe-h-0) {
  height: 0px;
}

#productessentials-page :is(.pe-h-0\.5) {
  height: 0.125rem;
}

#productessentials-page :is(.pe-h-12) {
  height: 3rem;
}

#productessentials-page :is(.pe-h-176) {
  height: 44rem;
}

#productessentials-page :is(.pe-h-48) {
  height: 12rem;
}

#productessentials-page :is(.pe-h-6) {
  height: 1.5rem;
}

#productessentials-page :is(.pe-h-full) {
  height: 100%;
}

#productessentials-page :is(.pe-min-h-80) {
  min-height: 20rem;
}

#productessentials-page :is(.pe-min-h-full) {
  min-height: 100%;
}

#productessentials-page :is(.pe-w-12) {
  width: 3rem;
}

#productessentials-page :is(.pe-w-6) {
  width: 1.5rem;
}

#productessentials-page :is(.pe-w-full) {
  width: 100%;
}

#productessentials-page :is(.pe-w-screen) {
  width: 100vw;
}

#productessentials-page :is(.pe-min-w-0) {
  min-width: 0px;
}

#productessentials-page :is(.pe-max-w-2xl) {
  max-width: 42rem;
}

#productessentials-page :is(.pe-max-w-3xl) {
  max-width: 48rem;
}

#productessentials-page :is(.pe-max-w-full) {
  max-width: 100%;
}

#productessentials-page :is(.pe-max-w-lg) {
  max-width: 32rem;
}

#productessentials-page :is(.pe-max-w-none) {
  max-width: none;
}

#productessentials-page :is(.pe-max-w-screen-md) {
  max-width: 768px;
}

#productessentials-page :is(.pe-max-w-xl) {
  max-width: 36rem;
}

#productessentials-page :is(.pe-flex-1) {
  flex: 1 1 0%;
}

#productessentials-page :is(.pe-transform) {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#productessentials-page :is(.pe-scroll-pb-0) {
  scroll-padding-bottom: 0px;
}

#productessentials-page :is(.pe-scroll-pb-0\.5) {
  scroll-padding-bottom: 0.125rem;
}

#productessentials-page :is(.pe-scroll-pb-1) {
  scroll-padding-bottom: 0.25rem;
}

#productessentials-page :is(.pe-scroll-pb-1\.5) {
  scroll-padding-bottom: 0.375rem;
}

#productessentials-page :is(.pe-scroll-pb-10) {
  scroll-padding-bottom: 2.5rem;
}

#productessentials-page :is(.pe-scroll-pb-11) {
  scroll-padding-bottom: 2.75rem;
}

#productessentials-page :is(.pe-scroll-pb-12) {
  scroll-padding-bottom: 3rem;
}

#productessentials-page :is(.pe-scroll-pb-14) {
  scroll-padding-bottom: 3.5rem;
}

#productessentials-page :is(.pe-scroll-pb-16) {
  scroll-padding-bottom: 4rem;
}

#productessentials-page :is(.pe-scroll-pb-2) {
  scroll-padding-bottom: 0.5rem;
}

#productessentials-page :is(.pe-scroll-pb-2\.5) {
  scroll-padding-bottom: 0.625rem;
}

#productessentials-page :is(.pe-scroll-pb-20) {
  scroll-padding-bottom: 5rem;
}

#productessentials-page :is(.pe-scroll-pb-24) {
  scroll-padding-bottom: 6rem;
}

#productessentials-page :is(.pe-scroll-pb-28) {
  scroll-padding-bottom: 7rem;
}

#productessentials-page :is(.pe-scroll-pb-32) {
  scroll-padding-bottom: 8rem;
}

#productessentials-page :is(.pe-scroll-pb-4) {
  scroll-padding-bottom: 1rem;
}

#productessentials-page :is(.pe-scroll-pb-40) {
  scroll-padding-bottom: 10rem;
}

#productessentials-page :is(.pe-scroll-pb-44) {
  scroll-padding-bottom: 11rem;
}

#productessentials-page :is(.pe-scroll-pb-48) {
  scroll-padding-bottom: 12rem;
}

#productessentials-page :is(.pe-scroll-pb-6) {
  scroll-padding-bottom: 1.5rem;
}

#productessentials-page :is(.pe-scroll-pb-60) {
  scroll-padding-bottom: 15rem;
}

#productessentials-page :is(.pe-scroll-pb-64) {
  scroll-padding-bottom: 16rem;
}

#productessentials-page :is(.pe-scroll-pb-8) {
  scroll-padding-bottom: 2rem;
}

#productessentials-page :is(.pe-scroll-pb-80) {
  scroll-padding-bottom: 20rem;
}

#productessentials-page :is(.pe-scroll-pt-0) {
  scroll-padding-top: 0px;
}

#productessentials-page :is(.pe-scroll-pt-0\.5) {
  scroll-padding-top: 0.125rem;
}

#productessentials-page :is(.pe-scroll-pt-1) {
  scroll-padding-top: 0.25rem;
}

#productessentials-page :is(.pe-scroll-pt-1\.5) {
  scroll-padding-top: 0.375rem;
}

#productessentials-page :is(.pe-scroll-pt-10) {
  scroll-padding-top: 2.5rem;
}

#productessentials-page :is(.pe-scroll-pt-11) {
  scroll-padding-top: 2.75rem;
}

#productessentials-page :is(.pe-scroll-pt-12) {
  scroll-padding-top: 3rem;
}

#productessentials-page :is(.pe-scroll-pt-14) {
  scroll-padding-top: 3.5rem;
}

#productessentials-page :is(.pe-scroll-pt-16) {
  scroll-padding-top: 4rem;
}

#productessentials-page :is(.pe-scroll-pt-2) {
  scroll-padding-top: 0.5rem;
}

#productessentials-page :is(.pe-scroll-pt-2\.5) {
  scroll-padding-top: 0.625rem;
}

#productessentials-page :is(.pe-scroll-pt-20) {
  scroll-padding-top: 5rem;
}

#productessentials-page :is(.pe-scroll-pt-24) {
  scroll-padding-top: 6rem;
}

#productessentials-page :is(.pe-scroll-pt-28) {
  scroll-padding-top: 7rem;
}

#productessentials-page :is(.pe-scroll-pt-32) {
  scroll-padding-top: 8rem;
}

#productessentials-page :is(.pe-scroll-pt-4) {
  scroll-padding-top: 1rem;
}

#productessentials-page :is(.pe-scroll-pt-40) {
  scroll-padding-top: 10rem;
}

#productessentials-page :is(.pe-scroll-pt-44) {
  scroll-padding-top: 11rem;
}

#productessentials-page :is(.pe-scroll-pt-48) {
  scroll-padding-top: 12rem;
}

#productessentials-page :is(.pe-scroll-pt-6) {
  scroll-padding-top: 1.5rem;
}

#productessentials-page :is(.pe-scroll-pt-60) {
  scroll-padding-top: 15rem;
}

#productessentials-page :is(.pe-scroll-pt-64) {
  scroll-padding-top: 16rem;
}

#productessentials-page :is(.pe-scroll-pt-8) {
  scroll-padding-top: 2rem;
}

#productessentials-page :is(.pe-scroll-pt-80) {
  scroll-padding-top: 20rem;
}

#productessentials-page :is(.pe-grid-cols-1) {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

#productessentials-page :is(.pe-flex-row) {
  flex-direction: row;
}

#productessentials-page :is(.pe-flex-row-reverse) {
  flex-direction: row-reverse;
}

#productessentials-page :is(.pe-flex-col) {
  flex-direction: column;
}

#productessentials-page :is(.pe-flex-col-reverse) {
  flex-direction: column-reverse;
}

#productessentials-page :is(.pe-items-start) {
  align-items: flex-start;
}

#productessentials-page :is(.pe-items-end) {
  align-items: flex-end;
}

#productessentials-page :is(.pe-items-center) {
  align-items: center;
}

#productessentials-page :is(.pe-justify-center) {
  justify-content: center;
}

#productessentials-page :is(.pe-justify-between) {
  justify-content: space-between;
}

#productessentials-page :is(.pe-gap-3) {
  gap: 0.75rem;
}

#productessentials-page :is(.pe-gap-x-6) {
  column-gap: 1.5rem;
}

#productessentials-page :is(.pe-gap-y-10) {
  row-gap: 2.5rem;
}

#productessentials-page :is(.pe-divide-y > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

#productessentials-page :is(.pe-divide-gray-200 > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

#productessentials-page :is(.pe-place-self-center) {
  place-self: center;
}

#productessentials-page :is(.pe-self-center) {
  align-self: center;
}

#productessentials-page :is(.pe-justify-self-center) {
  justify-self: center;
}

#productessentials-page :is(.pe-overflow-hidden) {
  overflow: hidden;
}

#productessentials-page :is(.pe-overflow-y-auto) {
  overflow-y: auto;
}

#productessentials-page :is(.pe-whitespace-nowrap) {
  white-space: nowrap;
}

#productessentials-page :is(.pe-break-words) {
  overflow-wrap: break-word;
}

#productessentials-page :is(.pe-break-all) {
  word-break: break-all;
}

#productessentials-page :is(.pe-rounded-full) {
  border-radius: 9999px;
}

#productessentials-page :is(.pe-rounded-lg) {
  border-radius: 0.5rem;
}

#productessentials-page :is(.pe-rounded-md) {
  border-radius: 0.375rem;
}

#productessentials-page :is(.pe-rounded-b-lg) {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

#productessentials-page :is(.pe-rounded-t-lg) {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

#productessentials-page :is(.pe-border) {
  border-width: 1px;
}

#productessentials-page :is(.pe-border-transparent) {
  border-color: transparent;
}

#productessentials-page :is(.pe-bg-amber-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-black) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-blue-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-cyan-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-emerald-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-fuchsia-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-gray-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-gray-300) {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-gray-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-gray-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-green-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-green-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-indigo-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-indigo-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-lime-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-neutral-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-orange-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-pink-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-purple-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-red-400) {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-red-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-rose-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-sky-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-slate-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-stone-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-teal-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-transparent) {
  background-color: transparent;
}

#productessentials-page :is(.pe-bg-violet-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-yellow-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-zinc-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

#productessentials-page :is(.pe-bg-opacity-75) {
  --tw-bg-opacity: 0.75;
}

#productessentials-page :is(.pe-bg-gradient-to-r) {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

#productessentials-page :is(.pe-from-amber-500) {
  --tw-gradient-from: #f59e0b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-black) {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-blue-500) {
  --tw-gradient-from: #3b82f6 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-cyan-500) {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-emerald-500) {
  --tw-gradient-from: #10b981 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-fuchsia-500) {
  --tw-gradient-from: #d946ef var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-gray-500) {
  --tw-gradient-from: #6b7280 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-gray-700) {
  --tw-gradient-from: #374151 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-green-500) {
  --tw-gradient-from: #22c55e var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-indigo-500) {
  --tw-gradient-from: #6366f1 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-lime-500) {
  --tw-gradient-from: #84cc16 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-neutral-500) {
  --tw-gradient-from: #737373 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-orange-500) {
  --tw-gradient-from: #f97316 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-pink-500) {
  --tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-purple-500) {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-red-400) {
  --tw-gradient-from: #f87171 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-red-500) {
  --tw-gradient-from: #ef4444 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-rose-500) {
  --tw-gradient-from: #f43f5e var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-sky-500) {
  --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-slate-500) {
  --tw-gradient-from: #64748b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-stone-500) {
  --tw-gradient-from: #78716c var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-teal-500) {
  --tw-gradient-from: #14b8a6 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-transparent) {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-violet-500) {
  --tw-gradient-from: #8b5cf6 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-white) {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-yellow-500) {
  --tw-gradient-from: #eab308 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-from-zinc-500) {
  --tw-gradient-from: #71717a var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-amber-500) {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-black) {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-blue-500) {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-cyan-500) {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #06b6d4 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-emerald-500) {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-fuchsia-500) {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d946ef var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-gray-500) {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-gray-700) {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #374151 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-green-500) {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #22c55e var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-indigo-500) {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-lime-500) {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-neutral-500) {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #737373 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-orange-500) {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f97316 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-pink-500) {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-purple-500) {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-red-400) {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f87171 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-red-500) {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-rose-500) {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f43f5e var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-sky-500) {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0ea5e9 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-slate-500) {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #64748b var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-stone-500) {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78716c var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-teal-500) {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #14b8a6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-transparent) {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-violet-500) {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-white) {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-yellow-500) {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #eab308 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-via-zinc-500) {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #71717a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#productessentials-page :is(.pe-to-amber-500) {
  --tw-gradient-to: #f59e0b var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-black) {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-blue-500) {
  --tw-gradient-to: #3b82f6 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-cyan-500) {
  --tw-gradient-to: #06b6d4 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-emerald-500) {
  --tw-gradient-to: #10b981 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-fuchsia-500) {
  --tw-gradient-to: #d946ef var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-gray-500) {
  --tw-gradient-to: #6b7280 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-gray-700) {
  --tw-gradient-to: #374151 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-green-500) {
  --tw-gradient-to: #22c55e var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-indigo-500) {
  --tw-gradient-to: #6366f1 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-lime-500) {
  --tw-gradient-to: #84cc16 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-neutral-500) {
  --tw-gradient-to: #737373 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-orange-500) {
  --tw-gradient-to: #f97316 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-pink-500) {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-purple-500) {
  --tw-gradient-to: #a855f7 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-red-400) {
  --tw-gradient-to: #f87171 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-red-500) {
  --tw-gradient-to: #ef4444 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-rose-500) {
  --tw-gradient-to: #f43f5e var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-sky-500) {
  --tw-gradient-to: #0ea5e9 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-slate-500) {
  --tw-gradient-to: #64748b var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-stone-500) {
  --tw-gradient-to: #78716c var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-teal-500) {
  --tw-gradient-to: #14b8a6 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-transparent) {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-violet-500) {
  --tw-gradient-to: #8b5cf6 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-white) {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-yellow-500) {
  --tw-gradient-to: #eab308 var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-to-zinc-500) {
  --tw-gradient-to: #71717a var(--tw-gradient-to-position);
}

#productessentials-page :is(.pe-box-decoration-slice) {
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice;
}

#productessentials-page :is(.pe-object-contain) {
  object-fit: contain;
}

#productessentials-page :is(.pe-object-cover) {
  object-fit: cover;
}

#productessentials-page :is(.pe-object-center) {
  object-position: center;
}

#productessentials-page :is(.pe-object-top) {
  object-position: top;
}

#productessentials-page :is(.pe-p-1) {
  padding: 0.25rem;
}

#productessentials-page :is(.pe-p-36) {
  padding: 9rem;
}

#productessentials-page :is(.pe-p-4) {
  padding: 1rem;
}

#productessentials-page :is(.pe-px-4) {
  padding-left: 1rem;
  padding-right: 1rem;
}

#productessentials-page :is(.pe-px-5) {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

#productessentials-page :is(.pe-py-16) {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

#productessentials-page :is(.pe-py-2) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#productessentials-page :is(.pe-py-3) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

#productessentials-page :is(.pe-py-4) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#productessentials-page :is(.pe-pb-0) {
  padding-bottom: 0px;
}

#productessentials-page :is(.pe-pb-0\.5) {
  padding-bottom: 0.125rem;
}

#productessentials-page :is(.pe-pb-1) {
  padding-bottom: 0.25rem;
}

#productessentials-page :is(.pe-pb-1\.5) {
  padding-bottom: 0.375rem;
}

#productessentials-page :is(.pe-pb-10) {
  padding-bottom: 2.5rem;
}

#productessentials-page :is(.pe-pb-11) {
  padding-bottom: 2.75rem;
}

#productessentials-page :is(.pe-pb-12) {
  padding-bottom: 3rem;
}

#productessentials-page :is(.pe-pb-14) {
  padding-bottom: 3.5rem;
}

#productessentials-page :is(.pe-pb-16) {
  padding-bottom: 4rem;
}

#productessentials-page :is(.pe-pb-2) {
  padding-bottom: 0.5rem;
}

#productessentials-page :is(.pe-pb-2\.5) {
  padding-bottom: 0.625rem;
}

#productessentials-page :is(.pe-pb-20) {
  padding-bottom: 5rem;
}

#productessentials-page :is(.pe-pb-24) {
  padding-bottom: 6rem;
}

#productessentials-page :is(.pe-pb-28) {
  padding-bottom: 7rem;
}

#productessentials-page :is(.pe-pb-32) {
  padding-bottom: 8rem;
}

#productessentials-page :is(.pe-pb-4) {
  padding-bottom: 1rem;
}

#productessentials-page :is(.pe-pb-40) {
  padding-bottom: 10rem;
}

#productessentials-page :is(.pe-pb-44) {
  padding-bottom: 11rem;
}

#productessentials-page :is(.pe-pb-48) {
  padding-bottom: 12rem;
}

#productessentials-page :is(.pe-pb-6) {
  padding-bottom: 1.5rem;
}

#productessentials-page :is(.pe-pb-60) {
  padding-bottom: 15rem;
}

#productessentials-page :is(.pe-pb-64) {
  padding-bottom: 16rem;
}

#productessentials-page :is(.pe-pb-8) {
  padding-bottom: 2rem;
}

#productessentials-page :is(.pe-pb-80) {
  padding-bottom: 20rem;
}

#productessentials-page :is(.pe-pb-96) {
  padding-bottom: 24rem;
}

#productessentials-page :is(.pe-pt-0) {
  padding-top: 0px;
}

#productessentials-page :is(.pe-pt-0\.5) {
  padding-top: 0.125rem;
}

#productessentials-page :is(.pe-pt-1) {
  padding-top: 0.25rem;
}

#productessentials-page :is(.pe-pt-1\.5) {
  padding-top: 0.375rem;
}

#productessentials-page :is(.pe-pt-10) {
  padding-top: 2.5rem;
}

#productessentials-page :is(.pe-pt-11) {
  padding-top: 2.75rem;
}

#productessentials-page :is(.pe-pt-12) {
  padding-top: 3rem;
}

#productessentials-page :is(.pe-pt-14) {
  padding-top: 3.5rem;
}

#productessentials-page :is(.pe-pt-16) {
  padding-top: 4rem;
}

#productessentials-page :is(.pe-pt-2) {
  padding-top: 0.5rem;
}

#productessentials-page :is(.pe-pt-2\.5) {
  padding-top: 0.625rem;
}

#productessentials-page :is(.pe-pt-20) {
  padding-top: 5rem;
}

#productessentials-page :is(.pe-pt-24) {
  padding-top: 6rem;
}

#productessentials-page :is(.pe-pt-28) {
  padding-top: 7rem;
}

#productessentials-page :is(.pe-pt-32) {
  padding-top: 8rem;
}

#productessentials-page :is(.pe-pt-4) {
  padding-top: 1rem;
}

#productessentials-page :is(.pe-pt-40) {
  padding-top: 10rem;
}

#productessentials-page :is(.pe-pt-44) {
  padding-top: 11rem;
}

#productessentials-page :is(.pe-pt-48) {
  padding-top: 12rem;
}

#productessentials-page :is(.pe-pt-5) {
  padding-top: 1.25rem;
}

#productessentials-page :is(.pe-pt-6) {
  padding-top: 1.5rem;
}

#productessentials-page :is(.pe-pt-60) {
  padding-top: 15rem;
}

#productessentials-page :is(.pe-pt-64) {
  padding-top: 16rem;
}

#productessentials-page :is(.pe-pt-8) {
  padding-top: 2rem;
}

#productessentials-page :is(.pe-pt-80) {
  padding-top: 20rem;
}

#productessentials-page :is(.pe-text-left) {
  text-align: left;
}

#productessentials-page :is(.pe-text-center) {
  text-align: center;
}

#productessentials-page :is(.pe-text-2xl) {
  font-size: 1.5rem;
  line-height: 2rem;
}

#productessentials-page :is(.pe-text-3xl) {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

#productessentials-page :is(.pe-text-4xl) {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

#productessentials-page :is(.pe-text-5xl) {
  font-size: 3rem;
  line-height: 1;
}

#productessentials-page :is(.pe-text-6xl) {
  font-size: 3.75rem;
  line-height: 1;
}

#productessentials-page :is(.pe-text-7xl) {
  font-size: 4.5rem;
  line-height: 1;
}

#productessentials-page :is(.pe-text-8xl) {
  font-size: 6rem;
  line-height: 1;
}

#productessentials-page :is(.pe-text-9xl) {
  font-size: 8rem;
  line-height: 1;
}

#productessentials-page :is(.pe-text-base) {
  font-size: 1rem;
  line-height: 1.5rem;
}

#productessentials-page :is(.pe-text-lg) {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

#productessentials-page :is(.pe-text-sm) {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

#productessentials-page :is(.pe-text-xl) {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#productessentials-page :is(.pe-text-xs) {
  font-size: 0.75rem;
  line-height: 1rem;
}

#productessentials-page :is(.pe-font-black) {
  font-weight: 900;
}

#productessentials-page :is(.pe-font-bold) {
  font-weight: 700;
}

#productessentials-page :is(.pe-font-extrabold) {
  font-weight: 800;
}

#productessentials-page :is(.pe-font-extralight) {
  font-weight: 200;
}

#productessentials-page :is(.pe-font-light) {
  font-weight: 300;
}

#productessentials-page :is(.pe-font-medium) {
  font-weight: 500;
}

#productessentials-page :is(.pe-font-normal) {
  font-weight: 400;
}

#productessentials-page :is(.pe-font-semibold) {
  font-weight: 600;
}

#productessentials-page :is(.pe-font-thin) {
  font-weight: 100;
}

#productessentials-page :is(.pe-italic) {
  font-style: italic;
}

#productessentials-page :is(.pe-leading-6) {
  line-height: 1.5rem;
}

#productessentials-page :is(.pe-tracking-tight) {
  letter-spacing: -0.025em;
}

#productessentials-page :is(.pe-text-amber-500) {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-black) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-blue-500) {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-cyan-500) {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-emerald-500) {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-fuchsia-500) {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-gray-700) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-gray-900) {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-green-500) {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-green-600) {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-indigo-500) {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-lime-500) {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-neutral-500) {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-orange-500) {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-pink-500) {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-purple-500) {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-red-500) {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-rose-500) {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-sky-500) {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-stone-500) {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-teal-500) {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-violet-500) {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-text-yellow-500) {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

#productessentials-page :is(.pe-underline) {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#productessentials-page :is(.pe-line-through) {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

#productessentials-page :is(.pe-decoration-4) {
  text-decoration-thickness: 4px;
}

#productessentials-page :is(.pe-underline-offset-4) {
  text-underline-offset: 4px;
}

#productessentials-page :is(.pe-placeholder-gray-500)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

#productessentials-page :is(.pe-opacity-60) {
  opacity: 0.6;
}

#productessentials-page :is(.pe-shadow) {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#productessentials-page :is(.pe-shadow-sm) {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#productessentials-page :is(.pe-shadow-xl) {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#productessentials-page :is(.pe-ring-1) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#productessentials-page :is(.pe-ring-slate-900\/10) {
  --tw-ring-color: rgb(15 23 42 / 0.1);
}

#productessentials-page :is(.pe-transition-all) {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#productessentials-page :is(.pe-transition-opacity) {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Polaris-Page {
  margin-bottom: var(--p-space-600) !important;
}

@media (min-width: 640px) {
  .sm\:pe-aspect-h-2 {
    --tw-aspect-h: 2;
  }

  .sm\:pe-aspect-h-3 {
    --tw-aspect-h: 3;
  }

  .sm\:pe-aspect-w-2 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 2;
  }

  .sm\:pe-aspect-w-2 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sm\:pe-aspect-w-3 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 3;
  }

  .sm\:pe-aspect-w-3 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:pe-aspect-none {
    position: static;
    padding-bottom: 0;
  }

  .lg\:pe-aspect-none > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

#productessentials-page :is(.hover\:pe-bg-gray-50:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

#productessentials-page :is(.hover\:pe-bg-indigo-400:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

#productessentials-page :is(.hover\:pe-bg-indigo-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

#productessentials-page :is(.hover\:pe-bg-opacity-80:hover) {
  --tw-bg-opacity: 0.8;
}

#productessentials-page :is(.focus\:pe-border-transparent:focus) {
  border-color: transparent;
}

#productessentials-page :is(.focus\:pe-outline-none:focus) {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#productessentials-page :is(.focus\:pe-ring-2:focus) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#productessentials-page :is(.focus\:pe-ring-white:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

#productessentials-page :is(.focus\:pe-ring-offset-2:focus) {
  --tw-ring-offset-width: 2px;
}

#productessentials-page :is(.focus\:pe-ring-offset-indigo-600:focus) {
  --tw-ring-offset-color: #4f46e5;
}

#productessentials-page :is(.focus-visible\:pe-ring-2:focus-visible) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#productessentials-page :is(.focus-visible\:pe-ring-indigo-500:focus-visible) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

#productessentials-page :is(.focus-visible\:pe-ring-offset-2:focus-visible) {
  --tw-ring-offset-width: 2px;
}

#productessentials-page :is(.pe-group:hover .group-hover\:pe-opacity-75) {
  opacity: 0.75;
}

#productessentials-page :is(.pe-group:hover .group-hover\:pe-opacity-80) {
  opacity: 0.8;
}

@media (min-width: 640px) {
  #productessentials-page :is(.sm\:pe-mx-auto) {
    margin-left: auto;
    margin-right: auto;
  }

  #productessentials-page :is(.sm\:pe-my-8) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  #productessentials-page :is(.sm\:pe-ml-3) {
    margin-left: 0.75rem;
  }

  #productessentials-page :is(.sm\:pe-mt-0) {
    margin-top: 0px;
  }

  #productessentials-page :is(.sm\:pe-mt-5) {
    margin-top: 1.25rem;
  }

  #productessentials-page :is(.sm\:pe-mt-6) {
    margin-top: 1.5rem;
  }

  #productessentials-page :is(.sm\:pe-flex) {
    display: flex;
  }

  #productessentials-page :is(.sm\:pe-hidden) {
    display: none;
  }

  #productessentials-page :is(.sm\:pe-w-full) {
    width: 100%;
  }

  #productessentials-page :is(.sm\:pe-max-w-full) {
    max-width: 100%;
  }

  #productessentials-page :is(.sm\:pe-max-w-lg) {
    max-width: 32rem;
  }

  #productessentials-page :is(.sm\:pe-max-w-none) {
    max-width: none;
  }

  #productessentials-page :is(.sm\:pe-max-w-xl) {
    max-width: 36rem;
  }

  #productessentials-page :is(.sm\:pe-grid-cols-2) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #productessentials-page :is(.sm\:pe-flex-row) {
    flex-direction: row;
  }

  #productessentials-page :is(.sm\:pe-flex-row-reverse) {
    flex-direction: row-reverse;
  }

  #productessentials-page :is(.sm\:pe-flex-col) {
    flex-direction: column;
  }

  #productessentials-page :is(.sm\:pe-flex-col-reverse) {
    flex-direction: column-reverse;
  }

  #productessentials-page :is(.sm\:pe-items-center) {
    align-items: center;
  }

  #productessentials-page :is(.sm\:pe-divide-x > :not([hidden]) ~ :not([hidden])) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  #productessentials-page :is(.sm\:pe-rounded-l-lg) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  #productessentials-page :is(.sm\:pe-rounded-l-none) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  #productessentials-page :is(.sm\:pe-rounded-r-lg) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  #productessentials-page :is(.sm\:pe-rounded-r-none) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #productessentials-page :is(.sm\:pe-p-0) {
    padding: 0px;
  }

  #productessentials-page :is(.sm\:pe-p-6) {
    padding: 1.5rem;
  }

  #productessentials-page :is(.sm\:pe-px-10) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  #productessentials-page :is(.sm\:pe-px-6) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #productessentials-page :is(.sm\:pe-py-24) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  #productessentials-page :is(.sm\:pe-pb-0) {
    padding-bottom: 0px;
  }

  #productessentials-page :is(.sm\:pe-text-2xl) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #productessentials-page :is(.sm\:pe-text-3xl) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  #productessentials-page :is(.sm\:pe-text-4xl) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  #productessentials-page :is(.sm\:pe-text-5xl) {
    font-size: 3rem;
    line-height: 1;
  }

  #productessentials-page :is(.sm\:pe-text-6xl) {
    font-size: 3.75rem;
    line-height: 1;
  }

  #productessentials-page :is(.sm\:pe-text-7xl) {
    font-size: 4.5rem;
    line-height: 1;
  }

  #productessentials-page :is(.sm\:pe-text-8xl) {
    font-size: 6rem;
    line-height: 1;
  }

  #productessentials-page :is(.sm\:pe-text-9xl) {
    font-size: 8rem;
    line-height: 1;
  }

  #productessentials-page :is(.sm\:pe-text-base) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #productessentials-page :is(.sm\:pe-text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  #productessentials-page :is(.sm\:pe-text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  #productessentials-page :is(.sm\:pe-text-xl) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #productessentials-page :is(.sm\:pe-text-xs) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  #productessentials-page :is(.md\:pe-mx-0) {
    margin-left: 0px;
    margin-right: 0px;
  }

  #productessentials-page :is(.md\:pe-w-auto) {
    width: auto;
  }

  #productessentials-page :is(.md\:pe-max-w-full) {
    max-width: 100%;
  }

  #productessentials-page :is(.md\:pe-max-w-lg) {
    max-width: 32rem;
  }

  #productessentials-page :is(.md\:pe-max-w-none) {
    max-width: none;
  }

  #productessentials-page :is(.md\:pe-max-w-xl) {
    max-width: 36rem;
  }

  #productessentials-page :is(.md\:pe-flex-row) {
    flex-direction: row;
  }

  #productessentials-page :is(.md\:pe-flex-row-reverse) {
    flex-direction: row-reverse;
  }

  #productessentials-page :is(.md\:pe-flex-col) {
    flex-direction: column;
  }

  #productessentials-page :is(.md\:pe-flex-col-reverse) {
    flex-direction: column-reverse;
  }

  #productessentials-page :is(.md\:pe-justify-between) {
    justify-content: space-between;
  }

  #productessentials-page :is(.md\:pe-self-center) {
    align-self: center;
  }

  #productessentials-page :is(.md\:pe-rounded-lg) {
    border-radius: 0.5rem;
  }

  #productessentials-page :is(.md\:pe-pt-0) {
    padding-top: 0px;
  }

  #productessentials-page :is(.md\:pe-text-left) {
    text-align: left;
  }

  #productessentials-page :is(.md\:pe-text-2xl) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #productessentials-page :is(.md\:pe-text-3xl) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  #productessentials-page :is(.md\:pe-text-4xl) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  #productessentials-page :is(.md\:pe-text-5xl) {
    font-size: 3rem;
    line-height: 1;
  }

  #productessentials-page :is(.md\:pe-text-6xl) {
    font-size: 3.75rem;
    line-height: 1;
  }

  #productessentials-page :is(.md\:pe-text-7xl) {
    font-size: 4.5rem;
    line-height: 1;
  }

  #productessentials-page :is(.md\:pe-text-8xl) {
    font-size: 6rem;
    line-height: 1;
  }

  #productessentials-page :is(.md\:pe-text-9xl) {
    font-size: 8rem;
    line-height: 1;
  }

  #productessentials-page :is(.md\:pe-text-base) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #productessentials-page :is(.md\:pe-text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  #productessentials-page :is(.md\:pe-text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  #productessentials-page :is(.md\:pe-text-xl) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #productessentials-page :is(.md\:pe-text-xs) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (min-width: 1024px) {
  @media (min-width: 768px) {
    #productessentials-page :is(.lg\:md\:pe-rounded-lg) {
      border-radius: 0.5rem;
    }
  }

  #productessentials-page :is(.lg\:pe-h-80) {
    height: 20rem;
  }

  #productessentials-page :is(.lg\:pe-h-full) {
    height: 100%;
  }

  #productessentials-page :is(.lg\:pe-w-full) {
    width: 100%;
  }

  #productessentials-page :is(.lg\:pe-max-w-4xl) {
    max-width: 56rem;
  }

  #productessentials-page :is(.lg\:pe-max-w-7xl) {
    max-width: 80rem;
  }

  #productessentials-page :is(.lg\:pe-max-w-full) {
    max-width: 100%;
  }

  #productessentials-page :is(.lg\:pe-max-w-lg) {
    max-width: 32rem;
  }

  #productessentials-page :is(.lg\:pe-max-w-none) {
    max-width: none;
  }

  #productessentials-page :is(.lg\:pe-max-w-screen-2xl) {
    max-width: 1536px;
  }

  #productessentials-page :is(.lg\:pe-max-w-screen-xl) {
    max-width: 1280px;
  }

  #productessentials-page :is(.lg\:pe-max-w-xl) {
    max-width: 36rem;
  }

  #productessentials-page :is(.lg\:pe-grid-cols-3) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  #productessentials-page :is(.lg\:pe-flex-row) {
    flex-direction: row;
  }

  #productessentials-page :is(.lg\:pe-flex-row-reverse) {
    flex-direction: row-reverse;
  }

  #productessentials-page :is(.lg\:pe-flex-col) {
    flex-direction: column;
  }

  #productessentials-page :is(.lg\:pe-flex-col-reverse) {
    flex-direction: column-reverse;
  }

  #productessentials-page :is(.lg\:pe-px-6) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #productessentials-page :is(.lg\:pe-px-8) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  #productessentials-page :is(.lg\:pe-text-2xl) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #productessentials-page :is(.lg\:pe-text-3xl) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  #productessentials-page :is(.lg\:pe-text-4xl) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  #productessentials-page :is(.lg\:pe-text-5xl) {
    font-size: 3rem;
    line-height: 1;
  }

  #productessentials-page :is(.lg\:pe-text-6xl) {
    font-size: 3.75rem;
    line-height: 1;
  }

  #productessentials-page :is(.lg\:pe-text-7xl) {
    font-size: 4.5rem;
    line-height: 1;
  }

  #productessentials-page :is(.lg\:pe-text-8xl) {
    font-size: 6rem;
    line-height: 1;
  }

  #productessentials-page :is(.lg\:pe-text-9xl) {
    font-size: 8rem;
    line-height: 1;
  }

  #productessentials-page :is(.lg\:pe-text-base) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #productessentials-page :is(.lg\:pe-text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  #productessentials-page :is(.lg\:pe-text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  #productessentials-page :is(.lg\:pe-text-xl) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #productessentials-page :is(.lg\:pe-text-xs) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (min-width: 1280px) {
  #productessentials-page :is(.xl\:pe-grid-cols-4) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  #productessentials-page :is(.xl\:pe-gap-x-8) {
    column-gap: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  #productessentials-page :is(.dark\:pe-bg-gray-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
}