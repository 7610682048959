:root {
    --pe-ab-size-flip-label-text: 0.6em; /* IE compat */
}

a.pe-announcement-bar-item {
    text-decoration: none !important;
}

#pe-announcement-bar {
    z-index: 7;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    line-height: 1.6;
    overflow: hidden;
    pointer-events: initial;
   /*min-height: 54px;*/
}

#pe-announcement-bar.top-push-site-down {
    top: 0;
    position: relative;
}

#pe-announcement-bar-holder.top-push-site-down {
    top: 0;
    position: relative;
}

#pe-announcement-bar.top-push-site-down.show-fadein-animation {
    -webkit-animation: pe-slideDown 1s 0.1s 1 ease forwards;
    -moz-animation: pe-slideDown 1s 0.1s 1 ease forwards;
    animation: pe-slideDown 1s 0.1s 1 ease forwards;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
}

#pe-announcement-bar.top-layover-site {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}

#pe-announcement-bar-holder.top-layover-site {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}

#pe-announcement-bar.top-layover-site.show-fadein-animation {
    -webkit-animation: pe-slideDown 1s 0.1s 1 ease forwards;
    -moz-animation: pe-slideDown 1s 0.1s 1 ease forwards;
    animation: pe-slideDown 1s 0.1s 1 ease forwards;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
}

#pe-announcement-bar.bottom-layover-site {
    bottom: 0;
    position: fixed;
}

#pe-announcement-bar-holder.bottom-layover-site {
    bottom: 0;
    position: fixed;
}

#pe-announcement-bar.bottom-layover-site.show-fadein-animation {
    -webkit-animation: pe-slideUp 1s 0.1s 1 ease forwards;
    -moz-animation: pe-slideUp 1s 0.1s 1 ease forwards;
    animation: pe-slideUp 1s 0.1s 1 ease forwards;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
}

.pe-announcement-bar-item input {
    border: 1px solid var(--pe-ab-color-text) !important;
    background-color: var(--pe-ab-color-background) !important;
    color: var(--pe-ab-color-text) !important;
    width: auto;
    margin: 0;
    height: 38px;
    min-height: 38px;
    max-width: 100%;
    line-height: 0.80;
    border-radius: 5px;
    font-family: var(--pe-ab-font);
    font-size: calc(var(--pe-ab-size-text) - 30%);
    display: inline-block;
    vertical-align: middle;
    padding: 8px 10px;
}

@media (max-width: 800px) {
    #pe-announcement-bar input {
        margin-bottom: 5px !important;
        margin-top: 5px !important;
    }
}

.pe-announcement-bar-item form {
    margin: 0;
}

.pe-announcement-bar-item ::placeholder {
    color: var(--pe-ab-color-text);
    opacity: 0.6;
    vertical-align: middle;
    padding: 5px;
}

.pe-announcement-bar-item :-ms-input-placeholder {
    color: var(--pe-ab-color-text);
    opacity: 0.6;
    vertical-align: middle;
    padding: 5px;
}

.pe-announcement-bar-item ::-ms-input-placeholder {
    color: var(--pe-ab-color-text);
    opacity: 0.6;
    vertical-align: middle;
    padding: 5px;
}

.tns-slider {
    display: flex;
    height: 100%;
}
  
#pe-announcement-bar-items > .tns-item {
    flex: 1;
    display: flex;
    align-items: stretch;
}

#pe-announcement-bar-items > .tns-slide-active {
    margin-right: unset !important;
}

.top-push-site-down #pe-announcement-bar-items > .tns-item,
.top-layover-site #pe-announcement-bar-items > .tns-item {
    vertical-align: top;
}

.bottom-layover-site #pe-announcement-bar-items > .tns-item {
    vertical-align: bottom;
}

#pe-announcement-bar > div:empty {
    display: block;
}

.pe-announcement-bar-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: center;
    align-content: center;
    padding: var(--pe-ab-padding-top-bottom) 30px;
    color: var(--pe-ab-color-text);
    font-size: var(--pe-ab-size-text);
    font-family: var(--pe-ab-font);
    background: var(--pe-ab-color-background);
    background-image: var(--pe-ab-image-background);
    flex: 1; 
}

.pe-notification-bar-text-right {
    color: var(--pe-ab-color-text-right) !important;
}

.pe-announcement-bar-item > div {
    display: flex;
    align-items: center;
    margin-left: var(--pe-ab-margin-elements);
    margin-right: var(--pe-ab-margin-elements);
    color: var(--pe-ab-color-text);
    font-size: var(--pe-ab-size-text);
    font-family: var(--pe-ab-font);
}

.pe-announcement-bar-item .close-btn {
    position: absolute; 
    right: 15px; 
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--pe-ab-color-text);
}

#pe-announcement-bar .close-btn {
    position: absolute; 
    right: 15px; 
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: var(--pe-ab-margin-elements);
    margin-right: var(--pe-ab-margin-elements);
    font-size: var(--pe-ab-size-text);
    font-family: var(--pe-ab-font);
    color: var(--pe-ab-color-text);
}

@media (max-width: 991px) {
    /* start of large tablet styles */
    .pe-announcement-bar-item {
        flex-wrap: nowrap;
        justify-content: center;
    }
    .pe-announcement-bar-item.show-button-mobile:not(.show-one-line-mobile), .pe-announcement-bar-item.show-right-text-mobile:not(.show-one-line-mobile) {
        flex-wrap: wrap;
    }
    .pe-announcement-bar-item {
        white-space: normal;
        padding: 4px 0px;
    }
    .pe-announcement-bar-item.show-close-button .pe-cta-btn {
        margin-right: 30px !important;
    }
    #pe-announcement-bar .close-btn, .pe-announcement-bar-item .close-btn {
        right: 10px !important;
    }
}

@media (max-width: 767px) {
    /* start of medium tablet styles */
    .pe-announcement-bar-item {
        font-size: 1.2em !important;
    }

    .pe-announcement-bar-item div {
        margin-left: 4px;
        margin-right: 4px;
    }

    .pe-cta-btn {
        margin: 0px !important;
        white-space: nowrap;
    }
}

@media (max-width: 479px) {
    /* 
        start of phone styles 
        NB: ANY CHANGE HERE MUST BE REPLICATED IN @container announcement-bar 
    */
    .pe-cta-btn {
        font-size: var(--pe-ab-size-text-button-mobile) !important;
    }

    .pe-announcement-bar-item:not(.show-countdown-mobile) .pe-notification-bar-flip-clock {
        display: none;
    }

    .pe-announcement-bar-item input {
        font-size: calc(var(--pe-ab-size-text-mobile) - 30%) !important;
    }

    .pe-announcement-bar-item {
        white-space: normal;
        padding: 4px 0px;
        font-size: var(--pe-ab-size-text-mobile) !important;
    }
    
    .pe-announcement-bar-item > div {
        font-size: var(--pe-ab-size-text-mobile) !important;
    }

    .pe-notification-bar-text-left {
        line-height: normal !important;
        padding-top: 4px;
        font-size: 1em !important;
    }

    .pe-notification-bar-text-right {
        line-height: normal !important;
        padding-top: 4px;
        font-size: 1em !important;
    }

    .pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot {
        font-size: var(--pe-ab-size-flip-label-text-mobile) !important;
    }

    .pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard {
        font-size: var(--pe-ab-size-flip-number-mobile) !important;
    }

    .pe-notification-bar-flip-clock > .flip-clock__piece {
        margin: 0 2px !important;
    }

    .pe-announcement-bar-item .desktop-only-field {
        display: none;
    }
    .desktop-only-field.pe-notification-bar-text-right {
        display: none;
    }
    .pe-announcement-bar-item.show-button-mobile .desktop-only-field.pe-notification-bar-btn {
        display: flex;
    }
    .pe-announcement-bar-item.show-right-text-mobile .desktop-only-field.pe-notification-bar-text-right {
        display: flex;
    }
    .pe-announcement-bar-item.show-one-line-mobile {
        flex-wrap: nowrap;
    }
    #pe-announcement-bar .close-btn, .pe-announcement-bar-item .close-btn {
        right: 10px !important;
        font-size: var(--pe-ab-size-text-mobile) !important;
    }
}

@container announcement-bar (width < 479px) {
    /* start of phone styles */
    .pe-cta-btn {
        font-size: var(--pe-ab-size-text-button-mobile) !important;
    }

    .pe-announcement-bar-item input {
        font-size: calc(var(--pe-ab-size-text-mobile) - 30%) !important;
    }

    .pe-announcement-bar-item {
        white-space: normal;
        padding: 4px 0px;
        font-size: var(--pe-ab-size-text-mobile) !important;
    }
    
    .pe-announcement-bar-item > div {
        font-size: var(--pe-ab-size-text-mobile) !important;
    }

    .pe-notification-bar-text-left {
        line-height: normal !important;
        padding-top: 4px;
        font-size: 1em !important;
    }

    .pe-notification-bar-text-right {
        line-height: normal !important;
        padding-top: 4px;
        font-size: 1em !important;
    }

    .pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot {
        font-size: var(--pe-ab-size-flip-label-text-mobile) !important;
    }

    .pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard {
        font-size: var(--pe-ab-size-flip-number-mobile) !important;
    }

    .pe-notification-bar-flip-clock > .flip-clock__piece {
        margin: 0 2px !important;
    }
}

.pe-cta-btn {
    color: var(--pe-ab-color-button-text) !important;
    font-size: var(--pe-ab-size-text-button);
    text-transform: uppercase !important;
    text-decoration: none !important;
    font-family: var(--pe-ab-font) !important;
    background: var(--pe-ab-color-button) !important;
    padding: 11px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    line-height: 1.1;
    transition: all 0.4s ease 0s;
    vertical-align: middle;
    margin: 5px;
    width: auto;
}

.pe-cta-btn:hover {
    opacity: 0.8;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
}

.pe-notification-bar-flip-clock *,
.pe-notification-bar-flip-clock *:before,
.pe-notification-bar-flip-clock *:after {
    box-sizing: border-box;
}

.pe-notification-bar-flip-clock {
    margin-top: 4px;
    line-height: 0.9;
    text-align: center;
    -webkit-perspective: 200px;
    perspective: 200px;
    display: flex;
    flex-direction: row;
}

.pe-notification-bar-flip-clock > .flip-clock__piece {
    margin: 0 4px;
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard {
    font-size: var(--pe-ab-size-flip-number);
    font-weight: inherit;
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard > .discoflipcard__top,
.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard > .discoflipcard__back::before,
.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard > .discoflipcard__back::after {
    background-color: var(--pe-ab-color-flip-top);
    color: var(--pe-ab-color-flip-number);
    border-radius: var(--pe-ab-size-flip-corner-radius) var(--pe-ab-size-flip-corner-radius) 0 0;
    font-family: var(--pe-ab-font-flip-number);
    font-weight: inherit;
    width: 1.80em;
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard > .discoflipcard__bottom,
.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard > .discoflipcard__back > .discoflipcard__bottom {
    border-top: none;
    background-color: var(--pe-ab-color-flip-bottom);
    color: var(--pe-ab-color-flip-number);
    border-radius: 0 0 var(--pe-ab-size-flip-corner-radius) var(--pe-ab-size-flip-corner-radius);
    font-family: var(--pe-ab-font-flip-number);
    font-weight: inherit;
    width: 1.80em;
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .discoflipcard > .discoflipcard__back {
    font-weight: inherit;
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot {
    font-size: var(--pe-ab-size-flip-label-text);
    color: var(--pe-ab-color-flip-label-text);
    display: inline;
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot_Days::before {
    content: var(--pe-ab-content-days);
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot_Hours::before {
    content: var(--pe-ab-content-hours);
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot_Minutes::before {
    content: var(--pe-ab-content-minutes);
}

.pe-notification-bar-flip-clock > .flip-clock__piece > .flip-clock__slot_Seconds::before {
    content: var(--pe-ab-content-seconds);
}

@-webkit-keyframes pe-slideDown {
    0% {
        -webkit-transform: translateY(-100%);
    }

    100% {
        -webkit-transform: none;
    }
}

@-moz-keyframes pe-slideDown {
    0% {
        -moz-transform: translateY(-100%);
    }

    100% {
        -moz-transform: none;
    }
}

@-webkit-keyframes pe-slideUp {
    0% {
        -webkit-transform: translateY(100%);
    }

    100% {
        -webkit-transform: none;
    }
}

@-moz-keyframes pe-slideUp {
    0% {
        -moz-transform: translateY(100%);
    }

    100% {
        -moz-transform: none;
    }
}