  .partner-carousel {
    position: relative;
    overflow: hidden;
    padding: 0;
    /* Add padding to account for button space */
  }

  .partner-carousel-inner {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }

  .partner-item {
    flex: 0 0 50%; /* Show 2 items on small screens */
    padding: 0 0.5rem;
  }

  .partner-item-faded {
    opacity: 0.3;
  }

  .partner-image {
    width: 48px;
    height: 48px;
    object-fit: fill;
    border-radius: 8px;
  }

  .partner-name {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .partner-description {
    color: #4b5563;
    margin-bottom: 1rem;
  }

  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel-button-left {
    left: 0.55rem;
  }

  .carousel-button-right {
    right: 0.55rem;
  }

  .carousel-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #4b5563;
  }

  
  @media (min-width: 768px) {
  
    .partner-item {
      flex: 0 0 33.33%; /* Show 3 items on medium screens */
    }
  
    .partner-name {
      font-size: 1.125rem;
    }
  
    .partner-description {
      font-size: 0.9375rem;
    }
  }
  
  @media (min-width: 1024px) {
    .partner-item {
      flex: 0 0 25%; /* Show 4 items on wider screens */
    }

  
    .partner-name {
      font-size: 1.25rem;
    }
  
    .partner-description {
      font-size: 1rem;
    }
  }