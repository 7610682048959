.CountdownTimer-Card {
    margin-top: 15px;
}

.Polaris-Choice {
    align-items: center;
    height: 100%;
}

.Polaris-PositionedOverlay {
    z-index: 6002 !important;
}

.CountdownTimer-Card .Polaris-LegacyCard__Section + .Polaris-LegacyCard__Section {
    padding: 0;
}

#button-layout-content {
    padding: var(--p-space-400) var(--p-space-400) var(--p-space-200) var(--p-space-400);
}

#mobile-layout-content {
    padding: var(--p-space-400) var(--p-space-400) var(--p-space-200) var(--p-space-400);
}

#timer-layout-content {
    padding: var(--p-space-400) var(--p-space-400) var(--p-space-200) var(--p-space-400);
}

#bar-layout-content {
    padding: var(--p-space-400) var(--p-space-400) var(--p-space-200) var(--p-space-400);
}

#effects-layout-content {
    padding: var(--p-space-400) var(--p-space-400) var(--p-space-200) var(--p-space-400);
}

#theme-selector {
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
}

#theme-selector > :first-child {
    margin-right: 10px;
    flex: 1;
}

#theme-selector > :last-child {
    align-self: center;
}

.sticky-container {
    position: sticky;
    top: 0;
    max-height: calc(100vh - 40px);
    overflow: auto;
}