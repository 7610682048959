.Polaris-Button .Polaris-Text--root {
    text-wrap: nowrap;
}

.Polaris-Button {
    gap: 0.4rem;
}

.live-chat-link {
    color: #215db0;
}