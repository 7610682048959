.onboarding-step {
    cursor: pointer;
    display: flex;
}

.onboarding-step:hover {
    background: #fafafa;
    border-radius: 0.5rem;
}

.onboarding-step.expanded {
    background: #f7f7f7;
    cursor: inherit;
    border-radius: 0.5rem;
}

.onboarding-step .icon {
    margin-left: 0.5rem;
    top: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.onboarding-step .circle-empty {
    height: 20px;
    width: 20px;
    border: 2px dashed var(--p-color-icon-secondary);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.onboarding-step .circle-empty:hover {
    border: 2px solid var(--p-color-icon-secondary);
}

.onboarding-step .icon .Polaris-Icon {
    margin: 0;
}

.onboarding-step .content-and-media {
    gap: 1rem;
    justify-content: space-between;
    margin-inline: 0.5rem;
    display: flex;
    width: 100%;
}

.onboarding-step .content-box {
    align-items: flex-start;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    display: flex;
    width: 100%;
}

.onboarding-step .heading {
    appearance: none;
    background: none;
    border: none;
    padding: calc(0.5rem + 0.125rem) 0;
    text-align: left;
    display: flex;
    width: 100%;
    align-items: center;
    color: inherit;
    display: flex;
    gap: 0.5rem;
}

.onboarding-step .content {
    margin-bottom: 0.5rem;
    width: 100%;
}

.onboarding-step .media {
    align-items: center;
    display: flex;
    margin-block: 0.5rem;
    max-height: 9.375rem;
    max-width: 15rem;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.onboarding-step .media .fade {
  --task-image-fade-color: #f7f7f7;
  background: linear-gradient(0deg,var(--task-image-fade-color),var(--task-image-fade-color) 60%,#fff0 100%);
  bottom: 0;
  height: 20%;
  position: absolute;
  right: 0;
  width: 100%;
}

.completed-steps {
    text-wrap: nowrap;
}